import React from 'react'
import { Container, Heading, Text } from 'rebass'
import { Twitter, Linkedin, Github } from 'styled-icons/fa-brands'

import { Avatar, Header, Link, NavBar } from '../components'
import Layout from '../layouts/default'

export default () => (
  <Layout>
    <NavBar />
    <Header image='/assets/img/banners/macbook-desk-1200x900.jpg'>
      <Avatar size={[200]} src='/assets/img/profile/ovhemert-200x200.jpg' alt='Profile picture'/>
      <Heading is='h1' fontSize={[4, 5, 6]} fontWeight='300' my={3}>Osmond van Hemert</Heading>
      <Text my={2} fontSize={[0, 1]} fontWeight='300'>Software Engineer / Architect / Open Source Geek</Text>
      <Text my={3}>
        <Link href='https://twitter.com/ovhemert' target='_blank' rel='noopener noreferrer' mx={2} title='Twitter'><Twitter size={24} /></Link>
        <Link href='https://www.linkedin.com/in/osmondvanhemert' target='_blank' rel='noopener noreferrer' mx={2} title='LinkedIn'><Linkedin size={24} /></Link>
        <Link href='https://github.com/ovhemert' target='_blank' rel='noopener noreferrer' mx={2} title='Github'><Github size={24} /></Link>
      </Text>
    </Header>
    <Container m={2}>
    
    </Container>
  </Layout>
)